export const TRANSITION_COHORT = [
    /* test accounts */
    's2222222',
    'student_test',
    /* from grad school */
    's001106',
    's006457',
    's109661',
    's3007596',
    's3043929',
    's3056718',
    's307916',
    's314714',
    's326199',
    's329539',
    's329966',
    's3302933',
    's3310069',
    's3319998',
    's3329297',
    's3334345',
    's3338618',
    's3338964',
    's3344899',
    's335405',
    's348601',
    's358788',
    's363471',
    's366936',
    's367907',
    's371151',
    's4003358',
    's4006532',
    's4010566',
    's4010623',
    's4030204',
    's4083572',
    's4094785',
    's4100192',
    's4116877',
    's4117532',
    's4118082',
    's4119897',
    's4121563',
    's4123612',
    's4132417',
    's4133024',
    's4133031',
    's4133070',
    's4138415',
    's4142554',
    's4142835',
    's4150115',
    's4154456',
    's4171725',
    's4171919',
    's4172529',
    's4174202',
    's4175571',
    's4176557',
    's4179358',
    's4181450',
    's4182842',
    's4187343',
    's4188552',
    's4190037',
    's4195918',
    's4200292',
    's4201633',
    's4202856',
    's4205666',
    's4206175',
    's4208171',
    's4208990',
    's4209558',
    's4209742',
    's4210420',
    's4212637',
    's4216318',
    's4217255',
    's4221700',
    's4223785',
    's4225594',
    's4228337',
    's4231791',
    's4234488',
    's4235099',
    's4235185',
    's4235575',
    's4237303',
    's4237314',
    's4239611',
    's4242038',
    's4242822',
    's4243155',
    's4243448',
    's4243822',
    's4244561',
    's4246779',
    's4251143',
    's4254427',
    's4257912',
    's4258076',
    's4258955',
    's4259275',
    's4261041',
    's4261724',
    's4262094',
    's4262622',
    's4263726',
    's4264088',
    's4264277',
    's4264369',
    's4264869',
    's4264910',
    's4265245',
    's4265374',
    's4266167',
    's4267661',
    's4269152',
    's4269555',
    's4270084',
    's4274426',
    's4278101',
    's4281419',
    's4282331',
    's4283416',
    's4283718',
    's4284834',
    's4284922',
    's4285804',
    's4286188',
    's4286615',
    's4287420',
    's4287462',
    's4287499',
    's4287622',
    's4287734',
    's4288189',
    's4288522',
    's4288872',
    's4289660',
    's4290365',
    's4290589',
    's4290868',
    's4291295',
    's4291942',
    's4293026',
    's4294111',
    's4294950',
    's4296376',
    's4298884',
    's4300877',
    's4300879',
    's4302448',
    's4304640',
    's4306102',
    's4307526',
    's4308328',
    's4310171',
    's4310288',
    's4311721',
    's4312188',
    's4314011',
    's4316746',
    's4316864',
    's4318683',
    's4319109',
    's4320585',
    's4320906',
    's4320935',
    's4321090',
    's4321165',
    's4321475',
    's4321659',
    's4321870',
    's4322249',
    's4323189',
    's4323867',
    's4324577',
    's4325087',
    's4325140',
    's4326315',
    's4327959',
    's4329925',
    's4332821',
    's4333362',
    's4333537',
    's4334673',
    's4335285',
    's4335410',
    's4339006',
    's4340342',
    's4341671',
    's4342155',
    's4342688',
    's4342693',
    's4342740',
    's4343664',
    's4345216',
    's4349199',
    's4351995',
    's4352272',
    's4355802',
    's4357144',
    's4359043',
    's4361389',
    's4362774',
    's4363778',
    's4363814',
    's4363845',
    's4364329',
    's4364353',
    's4365373',
    's4366339',
    's4367106',
    's4370095',
    's4370990',
    's4371670',
    's4372006',
    's4374287',
    's4374670',
    's4375344',
    's4377145',
    's4377716',
    's4378400',
    's4380871',
    's4383706',
    's4386117',
    's4391511',
    's4395444',
    's4396603',
    's4396896',
    's4397406',
    's4398301',
    's4398510',
    's4400852',
    's4404949',
    's4405002',
    's4405357',
    's4406094',
    's4406455',
    's4406846',
    's4406858',
    's4407031',
    's4407772',
    's4407857',
    's4408118',
    's4408805',
    's4408879',
    's4409574',
    's4409704',
    's4409881',
    's4410099',
    's4411802',
    's4413625',
    's4414191',
    's4415042',
    's4415045',
    's4417427',
    's4417459',
    's4417476',
    's4417679',
    's4417701',
    's4418132',
    's4418426',
    's4419902',
    's4419922',
    's4419950',
    's4420840',
    's4421726',
    's4422058',
    's4422259',
    's4423102',
    's4423661',
    's4424469',
    's4424519',
    's4424640',
    's4424641',
    's4424883',
    's4424992',
    's4425023',
    's4425196',
    's4425198',
    's4425766',
    's4426633',
    's4426957',
    's4427501',
    's4428873',
    's4428889',
    's4429132',
    's4430770',
    's4437740',
    's4437769',
    's4438298',
    's4438339',
    's4440076',
    's4440099',
    's4440191',
    's4440194',
    's4440207',
    's4440369',
    's4440395',
    's4440740',
    's4440894',
    's4441931',
    's4443772',
    's4445564',
    's4445799',
    's4446757',
    's4446929',
    's4447181',
    's4447974',
    's4449955',
    's4450762',
    's4451303',
    's4451435',
    's4452074',
    's4452684',
    's4453645',
    's4454009',
    's4454233',
    's4454268',
    's4454333',
    's4454412',
    's4454549',
    's4454669',
    's4454921',
    's4455584',
    's4455598',
    's4455682',
    's4455684',
    's4455724',
    's4455865',
    's4455938',
    's4455942',
    's4455994',
    's4456005',
    's4456141',
    's4456148',
    's4456149',
    's4456151',
    's4456183',
    's4456241',
    's4456251',
    's4456252',
    's4456278',
    's4456289',
    's4456291',
    's4456327',
    's4456500',
    's4456880',
    's4457022',
    's4457122',
    's4457295',
    's4458033',
    's4458180',
    's4458341',
    's4458489',
    's4458540',
    's4458994',
    's4460007',
    's4460108',
    's4460249',
    's4461161',
    's4461181',
    's4462169',
    's4462599',
    's4463820',
    's4463894',
    's4463898',
    's4465387',
    's4465520',
    's4466004',
    's4466705',
    's4467980',
    's4469770',
    's4470000',
    's4470493',
    's4471609',
    's4472087',
    's4472666',
    's4472707',
    's4472909',
    's4473003',
    's4473242',
    's4473268',
    's4474307',
    's4474374',
    's4474507',
    's4475176',
    's4475911',
    's4476019',
    's4476159',
    's4477242',
    's4477391',
    's4477654',
    's4481295',
    's4481445',
    's4481469',
    's4482522',
    's4482661',
    's4483183',
    's4483652',
    's4483661',
    's4486796',
    's4486913',
    's4486944',
    's4487067',
    's4487718',
    's4487823',
    's4487846',
    's4487881',
    's4488000',
    's4488010',
    's4488015',
    's4488113',
    's4488234',
    's4488261',
    's4488839',
    's4489236',
    's4489468',
    's4489614',
    's4489865',
    's4490452',
    's4490825',
    's4490838',
    's4492639',
    's4493378',
    's4493430',
    's4493658',
    's4495877',
    's4497551',
    's4497575',
    's4497760',
    's4497817',
    's4497834',
    's4497865',
    's4498052',
    's4498657',
    's4499143',
    's4499886',
    's4500081',
    's4501611',
    's4502232',
    's4504375',
    's4504830',
    's4505802',
    's4505840',
    's4506332',
    's4506590',
    's4507775',
    's4508504',
    's4509260',
    's4509780',
    's4512837',
    's4514642',
    's4514816',
    's4514849',
    's4516095',
    's4519176',
    's4519862',
    's4523355',
    's4524651',
    's4524676',
    's4527078',
    's4527457',
    's4538156',
    's4538327',
    's4543052',
    's4545664',
    's4551072',
    's4555775',
    's4557409',
    's4557691',
    's4558812',
    's4558813',
    's4558898',
    's4560514',
    's4561583',
    's4570710',
    's4577069',
    's4592458',
    's4618647',
    's4469777',
    's4445425',
    's4551356',
    's4595163',
    's4551357',
    's4551360',
    's4595203',
    's4571464',
    's4571536',
    's4604484',
    's4604654',
    's4595148',
    's4650897',
    's4595211',
    's4604740',
    's4604753',
    's4572626',
    's4122818',
    's4650911',
    's4571809',
    's4572383',
    's4604769',
    's4604826',
    's4626528',
    's4604818',
    's4623243',
    's4626933',
    's4627000',
    's4604786',
    's4604760',
    's4650905',
    's4595123',
];
